import React, { Fragment, useState, useEffect, useRef } from "react"
import {
  Subheading,
  Card,
  FormLayout,
  InlineError,
  TextField,
  TextStyle,
  Icon,
  Stack,
  Button,
  ButtonGroup,
  Checkbox,
  Spinner,
  ChoiceList,
} from "@shopify/polaris"
import {
  WandMajor,
  DeleteMajor,
  ChevronUpMinor,
  ChevronDownMinor,
} from "@shopify/polaris-icons"
import ProductPickerModal from "../../../../components/productPickerModal"
import selectPayloadToData from "../../../../helpers/selectPayloadToData"
import selectPayloadToCEData from "../../../../helpers/selectPayloadToCEData"
import getSellingPlans from "../../../../helpers/getSellingPlans"
import VersionColor from "./versionColor"
import update from "immutability-helper"
import DiscountCart from "../common/discountCart/discountCart"
import DiscountCartV2 from "../common/discountCartV2/discountCartV2"
import SubscriptionOption from "../common/subscriptionOption/subscriptionOption"
import styled from "styled-components"
const CouponWrapper = styled.div`
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`
const ProductPicker = styled.div`
  .mockInputContainer-error {
    box-shadow: 0px 0px 0px 2px #458fff;
  }
`
const DeleteWrapper = styled.div`
  cursor: pointer;
`
function ProductEdit(props) {
  const {
    token,
    shop,
    host,
    state,
    setState,
    productPickerShow,
    error,
    currency,
    currencySymbol = "$",
    version,
    index,
    p,
    setProductEdit
  } = props
  const [productPickerVisible, setProductPickerVisible] = useState(false)
  const [inlineError, setInlineError] = useState(null)
  const productRef = useRef()
  useEffect(() => {
    const element = productRef.current
    const elementRect = element.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.scrollY
    window.scrollTo({
      top: absoluteElementTop - 20,
      behavior: "smooth",
    })
  }, [])
  const deleteOneProduct = (index) => {
    if (state.product.length === 2 && 'tiles' === state.generalLayout && 'row' === state.layout) {
      setState(update(state, { layout: { $set: 'carousel' }, generalLayout: { $set: 'standard' }}))
    }else{
      setState(update(state, { product: { $splice: [[index, 1]] }, layout: { $set: 'carousel' }, generalLayout: { $set: 'standard' }}))
    }
  }
  return (
    <div
      ref={productRef}
      style={{ marginTop: "1.6rem" }}
      className="product-edit"
    >
      <VersionColor>
        <div
          className={
            version ? ("A" === version ? "version-a" : "version-b") : ""
          }
        >
          <Card sectioned key={index}>
            <Stack alignment="center">
              <Stack.Item fill>
                <Subheading>product {index + 1}</Subheading>
              </Stack.Item>
              <Stack.Item>
                <Stack>
                  <DeleteWrapper onClick={() => setProductEdit(-1)} >
                    <Icon source={ChevronUpMinor}/>
                  </DeleteWrapper>
                  <DeleteWrapper onClick={() => deleteOneProduct(index)}>
                    <Icon source={DeleteMajor} color="critical" />
                  </DeleteWrapper>
                </Stack>
              </Stack.Item>
            </Stack>
            <FormLayout>
              <div className="first-step">
              <FormLayout>
              <Fragment>
                <ProductPicker>
                  <div className="mockInputContainer">
                    <p
                      className="mockInputLabel"
                      style={{ paddingTop: "10px" }}
                    >
                      Offer product
                    </p>
                    <div
                      className={
                        !p.variants || !p.product
                          ? "mockInput mockInputContainer-error"
                          : "mockInput"
                      }
                      onClick={() => setProductPickerVisible(true)}
                    >
                      {!p.variants || !p.product ? (
                        <span>Select a product</span>
                      ) : (
                        <div>
                          <img
                            src={p.product.image}
                            className="mockInputImage"
                          />
                          <span>
                            {p.product.title} |{" "}
                            {p.variants
                              .map((variant) => variant.title)
                              .join("/")}
                          </span>
                        </div>
                      )}
                    </div>
                    {error && error.product ? (
                      <InlineError
                        message={error.product}
                        fieldID="product-error"
                      />
                    ) : (
                      false
                    )}
                    {inlineError && p.product && inlineError[p.product.id] ? (
                      <InlineError
                        message={inlineError[p.product.id]}
                        fieldID="product-error"
                      />
                    ) : (
                      false
                    )}
                    {
                      (p?.product && typeof p?.product === 'object' && Object.keys(p?.product).length === 0) || (p?.variants && Array.isArray(p?.variants) && p.variants.length === 0) ? (
                        <InlineError
                        message="The selected upsell product or variant was deleted from your store. This product won’t show untill you select a new product."
                        fieldID="product-error"
                      />
                      ) : (
                        false
                      )
                    }
                  </div>

                  {p?.product?.id && (
                    <SubscriptionOption
                      state={state}
                      setState={setState}
                      selectedProduct={p.product}
                      index={index}
                      token={token}
                      shop={shop}
                      host={host}
                    />
                  )}

                  {"product" === productPickerShow ? (
                    <ProductPickerModal
                      shop={shop}
                      host={host}
                      token={token}
                      open={productPickerVisible}
                      onSelect={(selectPayload) => {
                        let data
                        if ("extension" !== state.checkoutType) {
                          data = selectPayloadToData(selectPayload)
                        } else {
                          data = selectPayloadToCEData(selectPayload)
                        }
                        if (!data) {
                          if (p.product) {
                            setState(
                              update(state, {
                                product: {
                                  [index]: {
                                    product: { $set: null },
                                    variants: { $set: null },
                                  },
                                },
                              })
                            )
                          }
                          return
                        }

                        if (state.variantsTriggers.if.all[0].value) {
                          const variantsTriggers = {
                            if: {
                              all: [
                                {
                                  fact: "cartProducts",
                                  operator: "hasAny",
                                  value: null,
                                },
                                {
                                  fact: "cartProducts",
                                  operator: "Variant",
                                  value: null,
                                },
                              ],
                            },
                            then: {
                              all: [
                                {
                                  fact: "upsellVariant",
                                  operator: "Variant",
                                  value: null,
                                },
                              ],
                            },
                            swap: false,
                            type: "manual",
                          }
                          setState(
                            update(state, {
                              product: {
                                [index]: {
                                  title: {
                                    $set: data.product
                                      ? data.product.title
                                      : null,
                                  },
                                  product: { $set: data.product },
                                  variants: { $set: data.variants },
                                },
                              },
                              variantsTriggers: { $set: variantsTriggers },
                            })
                          )
                        } else {
                          setState(
                            update(state, {
                              product: {
                                [index]: {
                                  title: {
                                    $set: data.product
                                      ? data.product.title
                                      : null,
                                  },
                                  product: { $set: data.product },
                                  variants: { $set: data.variants },
                                },
                              },
                            })
                          )
                        }
                        const error = []
                        selectPayload.selection[0].variants.forEach(
                          (variant) => {
                            if (!variant.availableForSale) {
                              error.push(
                                `${variant.title} is not available for sale.`
                              )
                            }
                          }
                        )
                        const errorMessage = error.join(" ")
                        const allError = inlineError ? inlineError : {}
                        if (errorMessage) {
                          allError[selectPayload.selection[0].id] = errorMessage
                          setInlineError(allError)
                        } else {
                          if (
                            allError &&
                            allError[selectPayload.selection[0].id]
                          ) {
                            allError[selectPayload.selection[0].id] = null
                            setInlineError(allError)
                          }
                        }
                      }}
                      onClose={() => setProductPickerVisible(false)}
                      initialProduct={
                        p?.product
                          ? { product: p.product, variants: p.variants }
                          : null || null
                      }
                    />
                  ) : (
                    false
                  )}
                </ProductPicker>
              </Fragment>
              <TextField
                label="Offer Product Title"
                placeholder="Type the product title"
                onChange={(val) =>
                  setState(
                    update(state, {
                      product: { [index]: { title: { $set: val } } },
                    })
                  )
                }
                value={p.title}
              />
              <TextField
                label="Offer Product Subtitle"
                placeholder="Type the product subtitle"
                onChange={(val) =>
                  setState(
                    update(state, {
                      product: { [index]: { subtitle: { $set: val } } },
                    })
                  )
                }
                value={p.subtitle}
              />
              <TextField
                label="Marketing message (Optional)"
                placeholder="e.g. “Exclusive discount”"
                onChange={(val) =>
                  setState(
                    update(state, {
                      product: { [index]: { message: { $set: val } } },
                    })
                  )
                }
                value={p.message}
              />
              </FormLayout>
              </div>
              {"extension" === state.checkoutType &&
                p.variants &&
                p.variants.length > 1 && (
                  <div>
                    <p>Variant selector</p>
                    <Checkbox
                      label="Require a selection"
                      checked={p.requireSelection}
                      onChange={(val) =>
                        setState(
                          update(state, {
                            product: {
                              [index]: { requireSelection: { $set: val } },
                            },
                          })
                        )
                      }
                    />
                    <p style={{ color: "#6D7175" }}>
                      If checked, the variant dropdown selector is empty by
                      default
                    </p>
                  </div>
                )}
              <div>
                {"extension" === state.checkoutType &&
                state?.versionB === null ? (
                  <CouponWrapper>
                    <DiscountCartV2
                      discount={p.compareAtPrice}
                      state={state}
                      setState={setState}
                      productIndex={index}
                      currency={currency}
                      currencySymbol={currencySymbol}
                      isAi={false}
                    />
                  </CouponWrapper>
                ) : (
                  <DiscountCart
                    state={state}
                    setState={setState}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    isAi={false}
                    index={index}
                  />
                )}
              </div>
              {"extension" === state.checkoutType &&
              <div>
                <TextField
                  label="Manually override original product price:"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { price: { $set: val } } },
                      })
                    )
                  }
                  type="number"
                  value={p.price}
                  autoComplete="off"
                  prefix={currencySymbol}
                />
                <p style={{ color: "#6D7175", marginTop:'5px' }}>
                This will not affect discounts, the actual product price or any other price related setttings. This will only change the original product price value on the frontend of the upsell offer
                    </p>
              </div>
              }
            </FormLayout>
          </Card>
        </div>
      </VersionColor>
    </div>
  )
}

export default ProductEdit
